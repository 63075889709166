/* -------------------------------------------------------------------------- */
/*                                 Glightbox                                */
/* -------------------------------------------------------------------------- */

const glightboxInit = () => {
  if (window.GLightbox) {
    window.GLightbox({
      selector: ".gallery-default"
    });
    
    window.GLightbox({
      selector: ".gallery-0"
    });
    
    window.GLightbox({
      selector: ".gallery-1"
    });
    
    window.GLightbox({
      selector: ".gallery-2"
    });
    
    window.GLightbox({
      selector: ".gallery-3"
    });
    
    window.GLightbox({
      selector: ".gallery-4"
    });
    
    window.GLightbox({
      selector: ".gallery-5"
    });
  }
};

// trigger event listener callback function
function triggerLightBox(targetElem) {
  return function() {
    targetElem.click();
  }
}

// Find all triggers and set up an eventlistener for them
const lightBoxTriggers = document.querySelectorAll('button[data-trigger-lightbox]');
lightBoxTriggers.forEach(trigger => {
  var targetElem = document.getElementById(trigger.dataset.triggerLightbox);
  trigger.addEventListener('click', triggerLightBox(targetElem)); 
});


export default glightboxInit;
